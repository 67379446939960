.wedding-info {
  @include flex-center-between();
  @include pxRem(padding, 100 20);
  @include pxRem(font-size, 18);
  position: relative;
  color: map-get($color, "white");
  background-color: map-get($color, primary);
  .wedding-address,
  .wedding-contact {
    p {
      @include pxRem(margin-bottom, 5);
    }
  }
  .wedding-protagonist {
    @include pxRem(font-size, 40);
    @include transform(translateX(-50%));
    position: absolute;
    left: 50%;
    font-family: $font-vibes;
    text-align: center;
    justify-self: center;
    &::before,
    &::after {
      @include pxRem(width, 120);
      @include pxRem(height, 110);
      content: "";
      position: absolute;
      top: 0;
      opacity: 0.5;
      background-image: url("../../assets/images/leaf-small.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &::before {
      @include transform(scaleX(-1) rotate(60deg));
      right: calc(100% - 15px);
    }
    &::after {
      @include transform(rotate(60deg));
      left: calc(100% - 15px);
    }
    p {
      @include pxRem(margin, 8 0 0);
      @include pxRem(font-size, 16);
      line-height: 0;
    }
  }
}

@include respond-below(large-max) {
  .wedding-info {
    @include pxRem(font-size, 16);
    @include pxRem(padding, 50 20);
    flex-direction: column-reverse;
    .wedding-address,
    .wedding-contact {
      text-align: center;
    }
    .wedding-protagonist {
      @include pxRem(margin, 20 0 50);
      @include transform(none);
      position: relative;
      left: 0;
    }
  }
}
