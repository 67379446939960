// Mixins for build grid
$grid-columns: 12;
@mixin build-grid($size) {
  @for $i from 1 through $grid-columns {
    @if $size != "" {
      .col-#{$size}-#{$i} {
        flex: 0 0 percentage($i / $grid-columns);
        max-width: percentage($i / $grid-columns);
      }
    } @else {
      .col-#{$i} {
        flex: 0 0 percentage($i / $grid-columns);
        max-width: percentage($i / $grid-columns);
      }
    }
  }
}
// End mixins for build grid
$grid-gutter: 24px;
$container-width: 1180px;
.container {
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
  margin: 0 auto;
  width: 100%;
  max-width: $container-width;
  position: relative;
  @include respond-below(medium) {
    padding: 0 12px;
  }
}
.no-container {
  margin: 0 -24px;
  @include respond-below(medium) {
    margin: 0 -12px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$grid-gutter;
  margin-right: -$grid-gutter;
  @include respond-below(medium) {
    margin: 0 -12px;
  }
  > [class*="col-"] {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
    @include respond-below(medium) {
      padding: 0 12px;
    }
  }
}

// Remove gutters (margin, padding) of row & col
.row-no-gutters {
  margin-left: 0;
  margin-right: 0;
  > [class*="col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Grid for all screen
 * Ex: col-1, col-2 */
@include build-grid("");

$break-points: (
  small: sm,
  medium: md,
  wide: wide,
  large: lg,
  largest: xl 
);

/* Ex :Grid for screen min-width: $small
* col-sm-1, col-sm-2 */
@each $name, $value in $break-points {
  @include respond-above($name) {
    @include build-grid($value);
  }
}
