.parallax {
  height: 100%;
  background-image: url("../../assets/images/invitation-background.jpeg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include respond-below(large-pro) {
    background-attachment: scroll;
  }
}
