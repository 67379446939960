// Flex grid build
.d-flex {
  display: flex;
  &-inline {
    display: inline-flex;
  }
}
.flex {
  &-column {
    flex-direction: column;
  }
  &-column-reverse {
    flex-direction: column-reverse;
  }
  &-row {
    flex-direction: row;
  }
  &-row-reverse {
    flex-direction: row-reverse;
  }
  &-nowrap {
    flex-wrap: nowrap;
  }
  &-wrap {
    flex-wrap: wrap;
  }
  &-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  &-break {
    flex-basis: 100%;
  }
}
.justify-content {
  &-start {
    justify-content: flex-start;
  }
  &-end {
    justify-content: flex-end;
  }
  &-center {
    justify-content: center;
  }
  &-between {
    justify-content: space-between;
  }
  &-around {
    justify-content: space-around;
  }
}
.align-items {
  &-start {
    align-items: flex-start;
  }
  &-end {
    align-items: flex-end;
  }
  &-center {
    align-items: center;
  }
  &-baseline {
    align-items: baseline;
  }
  &-stretch {
    align-items: stretch;
  }
}
.align-self {
  &-start {
    align-self: flex-start;
  }
  &-end {
    align-self: flex-end;
  }
  &-center {
    align-self: center;
  }
  &-baseline {
    align-self: baseline;
  }
  &-stretch {
    align-self: stretch;
  }
}
.flex-center-x {
  display: flex;
  justify-content: center;
}
.flex-center-y {
  display: flex;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex-end-between {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.flex-col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-start-between {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
