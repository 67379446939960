@include respond-below(medium) {
  .section-title {
    .title {
      @include pxRem(font-size, 38);
      @include pxRem(margin, 30 0);
    }
  }
  .section-landscape {
    @include pxRem(padding, 0 20);
    max-height: 100vh;
    background-image: url("../../assets/images/invitation-background.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
      background-color: rgba(map-get($color, secondary), 0.75);
    }
    &::after {
      border-width: 0 0 50px 100vw;
    }
    .video-landscape {
      display: none;
    }
    .section-content {
      .wedding-main {
        @include pxRem(margin-bottom, 20);
      }
      .title {
        @include pxRem(padding, 30 10);
        @include pxRem(font-size, 50);
        span {
          @include pxRem(font-size, 20);
          @include pxRem(margin, 10 0 0);
          display: block;
          text-align: center;
        }
      }
      .tagline {
        @include pxRem(font-size, 18);
        @include pxRem(margin-bottom, 20);
      }
      .countdown-timer {
        span {
          @include pxRem(min-width, 60);
          p {
            @include pxRem(padding, 5 15);
            &:first-child {
              @include pxRem(font-size, 40);
            }
          }
        }
      }
    }
  }
  .section-introduction {
    .user-card {
      @include pxRem(margin-bottom, 50);
      flex-direction: column;
      &.user-card-reverse {
        align-items: center;
        flex-direction: column;
        margin-bottom: 0;
        .user-image {
          margin: 0;
        }
        .user-info {
          margin: 0;
          align-self: center;
        }
      }
    }
    .flower-icon {
      display: none;
    }
    .user-info {
      @include pxRem(padding, 30);
      @include pxRem(border-width, 20);
      transform: translateY(-30px) !important;
      position: static;
      width: 95% !important;
      margin: 0 auto;
      .user-name {
        @include pxRem(font-size, 28);
      }
      .user-role {
        @include pxRem(font-size, 16);
      }
      .user-desc {
        @include pxRem(font-size, 14);
        @include pxRem(margin-bottom, 20);
      }
    }
    .user-image,
    .user-info {
      width: 100%;
    }
  }
  .section-story {
    .section-title {
      @include pxRem(padding, 30 0);
      @include pxRem(margin-bottom, 30);
      .section-divider-top {
        @include pxRem(padding-top, 30);
        height: 0;
        &::before {
          border-width: 40px 100vw 0 0;
        }
      }
      .section-divider-bottom {
        @include pxRem(padding-bottom, 30);
        height: 0;
        &::before {
          border-width: 40px 0 0 100vw;
        }
      }
      .title {
        @include pxRem(margin, 50 0 35);
      }
    }
    .story-year {
      @include pxRem(margin-bottom, 40);
      @include pxRem(font-size, 22);
    }
    .timeline-item {
      &::before,
      &::after {
        display: none;
      }
      &::before {
        top: -50px;
        right: -10%;
      }
      &::after {
        display: none;
      }
      &.timeline-flex {
        @include flex-center-y();
        flex-direction: column;
      }
      &.timeline-video {
        margin-bottom: 0;
        &::before {
          display: none;
        }
        .timeline-date {
          @include pxRem(top, -30);
          @include pxRem(right, 5);
          transform: none;
          left: auto;
        }
        .timeline-story {
          transform: none;
          margin: -32% 0 0 ;
        }
      }
    }
    .timeline-date {
      @include pxRem(border-width, 5);
      @include pxRem(padding, 10 12);
      @include pxRem(font-size, 15);
      right: 15%;
      left: auto;
      .double-border {
        @include pxRem(padding, 14 20 12);
        width: 100%;
        white-space: nowrap;
        &::before {
          @include pxRem(top, 6);
          height: calc(100% - 12px);
        }
        &::after {
          @include pxRem(left, 6);
          width: calc(100% - 12px);
        }
      }
    }
    .timeline-images {
      .image-item {
        z-index: -1;
        &:nth-child(odd) {
          margin: 0;
        }
      }
    }
    .timeline-story {
      max-width: 100%;
      margin: -10px 0 0;
      .title {
        @include pxRem(font-size, 18);
      }
      .content {
        @include pxRem(font-size, 14);
      }
    }
    .timeline-slider {
      @include pxRem(margin-bottom, 30);
      width: 100%;
      & + .timeline-story {
        transform: none;
        min-width: auto;
      }
      .timeline-slider-actions {
        @include pxRem(border-width, 5);
        @include pxRem(left, 10);
        .timeline-slider-button {
          @include pxRem(height, 40);
        }
      }
    }
    .player-container {
      @include pxRem(width, 350);
      @include pxRem(left, 10);
    }
    .stories {
      .story-item {
        @include pxRem(margin-bottom, 80);
      }
      .story-final {
        @include pxRem(padding, 10 0 0);
        margin-top: 0;
        .story-icon {
          @include pxRem(font-size, 50);
        }
        h4 {
          @include pxRem(font-size, 20);
        }
        h2 {
          @include pxRem(font-size, 40);
        }
      }
    }
  }
  .section-invitation {
    @include pxRem(padding, 70 0);
    .double-border {
      @include pxRem(padding, 20);
    }
    .section-divider-top {
      @include pxRem(padding-top, 60);
      height: 0;
      &::before {
        border-width: 60px 100vw 0 0;
      }
    }
    .section-divider-bottom {
      @include pxRem(padding-bottom, 60);
      height: 0;
      &::after {
        border-width: 0 0 60px 100vw;
      }
    }
    .section-title {
      .title {
        @include pxRem(margin, 40 0);
      }
    }
    .section-content {
      @include pxRem(margin-bottom, 80);
    }
    .left-side {
      @include pxRem(margin-bottom, 20);
    }
    .wedding-invitation-cover {
      @include pxRem(padding, 100 0);
      h2 {
        @include pxRem(font-size, 50);
        margin: 0;
        &::before,
        &::after {
          @include pxRem(width, 100);
          @include pxRem(height, 80);
        }
        p {
          @include pxRem(font-size, 12);
          @include pxRem(padding, 10 0 8);
        }
      }
    }
    .wedding-invitation-content {
      @include pxRem(padding, 30 0);
      .tagline {
        @include pxRem(font-size, 16);
      }
      .wedding-datetime {
        @include pxRem(padding, 35 0);
        .date {
          @include pxRem(font-size, 22);
        }
        .time {
          @include pxRem(font-size, 20);
        }
      }
      .wedding-place {
        @include pxRem(font-size, 15);
        .restaurant-name {
          @include pxRem(font-size, 18);
        }
      }
    }
  }
  .section-location {
    .section-title {
      .title {
        @include pxRem(margin, 50 0);
      }
    }
    .google-map {
      @include pxRem(height, 350);
    }
  }
}
