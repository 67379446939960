@include respond-below(large-pro) {
  .section-landscape {
    .section-content {
      .title {
        @include flex-column-centered();
        span {
          @include pxRem(font-size, 30);
          @include pxRem(margin, 10 0 0);
          display: block;
          text-align: center;
        }
      }
    }
  }
  .section-introduction {
    .user-card {
      &.user-card-reverse {
        .flower-icon {
          transform: scaleY(-1);
          top: 25%;
          left: 8%;
        }
      }
    }
    .flower-icon {
      transform: translate(-22%, 25%) rotate(-180deg) scaleY(-1);
    }
    .user-info {
      @include pxRem(border-width, 25);
      @include pxRem(padding, 30);
      width: 40%;
      .user-name {
        @include pxRem(font-size, 30);
      }
      .user-role {
        @include pxRem(font-size, 16);
        @include pxRem(margin-bottom, 15);
      }
      .user-desc {
        @include pxRem(font-size, 14);
        @include pxRem(margin-bottom, 25);
      }
    }
  }
  .section-story {
    .section-title {
      @include pxRem(padding, 120 0 150);
      .section-divider-top {
        @include pxRem(padding-top, 80);
        &::before {
          border-width: 60px 105vw 0 0;
        }
      }
      .section-divider-top {
        @include pxRem(padding-bottom, 80);
        &::after {
          border-width: 60px 0 0 105vw;
        }
      }
    }
    .story-item {
      @include pxRem(margin-bottom, 80);
    }
    .timeline-item {
      &::before,
      &::after {
        @include pxRem(width, 300);
        @include pxRem(height, 280);
      }
      &::before {
        transform: translate(50%, -65%);
        top: 28%;
        left: 44%;
      }
      &::after {
        display: none;
      }
      &.timeline-flex {
        flex-direction: column;
        &::after {
          transform: translate(0, 25%);
          left: 0;
          bottom: -13%;
        }
      }
      &.timeline-video {
        &::before {
          transform: translate(60%, -25%);
          top: 15%;
          right: 0;
        }
        .player-container {
          position: static;
          z-index: 1;
        }
        .timeline-date {
          transform: translateX(15%);
          top: 20%;
        }
        .timeline-images {
          transform: translateY(-10px);
          z-index: 0;
          .image-item {
            &:nth-child(odd) {
              margin-top: 40%;
            }
            &:nth-child(even) {
              margin-top: 0;
            }
          }
        }
        .timeline-story {
          transform: translateX(-16%);
          margin: -35% 0 0 50%;
        }
      }
    }
    .timeline-date {
      top: 30%;
      left: 52%;
    }
    .image-item {
      width: 100%;
    }
    .timeline-slider {
      transform: translateX(-50%);
      left: 50%;
      width: calc(100vw - 50px);
      & + .timeline-story {
        transform: translate(-35%);
        align-self: flex-end;
        margin: 0;
      }
      .timeline-slider-actions {
        @include pxRem(left, 10);
      }
    }
    .timeline-story {
      translate: none;
      max-width: 60%;
      width: 100%;
      margin: -20% 0 0 5%;
    }
  }
  .section-invitation {
    @include pxRem(padding, 70 0);
    .wedding-invitation-cover {
      @include pxRem(padding, 200 0);
      h2 {
        @include pxRem(font-size, 80);
        &::before,
        &::after {
          @include pxRem(width, 150);
          @include pxRem(height, 130);
        }
        p {
          @include pxRem(font-size, 16);
          @include pxRem(padding, 15 0 0);
        }
      }
    }
    .wedding-invitation-content {
      .tagline {
        @include pxRem(font-size, 18);
        @include pxRem(margin-bottom, 40);
      }
      .wedding-datetime {
        @include pxRem(margin-bottom, 40);
        &::before,
        &::after {
          @include pxRem(width, 150);
          @include pxRem(height, 20);
        }
        .date {
          @include pxRem(font-size, 26);
        }
        .time {
          @include pxRem(font-size, 22);
        }
      }
      .wedding-place {
        @include pxRem(font-size, 16);
        .restaurant-name {
          @include pxRem(font-size, 18);
        }
      }
    }
  }
  .section-location {
    .google-map {
      @include pxRem(height, 400);
    }
  }
}

@include respond-below(large-max) {
  .section-introduction {
    .user-card {
      &.user-card-reverse {
        .flower-icon {
          transform: scaleY(-1);
          top: 36%;
          left: 3%;
        }
      }
    }
    .flower-icon {
      transform: translate(-8%, 8%) rotate(-180deg) scaleY(-1);
    }
    .user-info {
      width: 45%;
    }
  }
  .section-story {
    .timeline-date {
      top: 25%;
    }
    .timeline-item {
      &::before {
        left: 42%;
      }
    }
  }
}

@include respond-below(wide) {
  .section-landscape {
    margin-bottom: 0;
  }
  .section-introduction {
    .user-card {
      @include pxRem(margin-bottom, 120);
      &:last-child {
        margin-bottom: 0;
      }
    }
    .user-info {
      width: 60%;
    }
    .flower-icon {
      display: none;
    }
  }
  .section-story {
    .timeline-item {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
