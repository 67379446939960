.landing-page {
  overflow-x: hidden;
}
.section-title {
  .title {
    @include flex-center-y();
    @include pxRem(font-size, 50);
    @include pxRem(margin, 70 auto 35);
    flex-direction: column;
    position: relative;
    z-index: 2;
    color: map-get($color, "white");
    .underline-icon {
      @include pxRem(margin, 10 0);
      display: flex;
      opacity: 0.5;
    }
  }
}
.section-landscape {
  @include flex-centered();
  @include pxRem(padding, 75 0 145);
  @include pxRem(margin-bottom, 50);
  position: relative;
  height: 100vh;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(map-get($color, secondary), 0.5);
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 100px 100vw;
    border-color: transparent transparent map-get($color, "white") transparent;
  }
  .video-landscape {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    background-size: cover;
    transition: 1s opacity;
  }
  .section-content {
    @include flex-column-centered();
    color: map-get($color, "white");
    z-index: 2;
    .wedding-main {
      @include flex-column-centered();
      @include pxRem(margin-bottom, 40);
      font-family: $font-vibes;
    }
    .title {
      @include pxRem(font-size, 90);
      @include pxRem(padding, 60 0);
      line-height: 1;
      margin-bottom: 0;
      span {
        @include pxRem(font-size, 40);
      }
    }
    .svg-arrow {
      width: 30%;
    }
    .tagline {
      @include pxRem(font-size, 24);
      @include pxRem(margin-bottom, 40);
      font-style: italic;
      text-align: center;
    }
    .countdown-timer {
      @include flex-centered();
      span {
        @include flex-centered();
        @include pxRem(min-width, 135);
        flex-direction: column;
        border-right: 1px solid rgba(map-get($color, "white"), 0.3);
        &:last-child {
          border-right: none;
        }
        p {
          @include pxRem(font-size, 16);
          @include pxRem(padding, 5 22);
          line-height: 1;
          text-transform: uppercase;
          &:first-child {
            @include pxRem(font-size, 70);
          }
        }
      }
    }
  }
}
.section-introduction {
  @include pxRem(padding, 80 0);
  .user-card {
    @include pxRem(margin-bottom, 200);
    display: flex;
    position: relative;
    &.user-card-reverse {
      @include pxRem(margin-bottom, 50);
      flex-direction: row-reverse;
      .user-image {
        margin-left: -10%;
      }
      .user-info {
        align-self: flex-start;
        margin: -4% 5% 0 0;
      }
      .flower-icon {
        transform: translate(95%, -95%) scaleY(-1);
        top: 100%;
        right: 100%;
      }
    }
  }
  .flower-icon {
    transform: translate(5%, -8%) rotate(-180deg) scaleY(-1);
    position: absolute;
    right: 0;
    top: 0;
    width: 35%;
    z-index: 0;
  }
  .user-image,
  .user-info {
    @include clearfix();
  }

  .user-image {
    width: 40%;
    height: 100%;
    z-index: 0;
  }

  .user-info {
    @include pxRem(padding, 50);
    @include flex-centered();
    flex-direction: column;
    align-self: flex-end;
    position: relative;
    width: 50%;
    height: auto;
    margin: 0 0 -4% -5%;
    border: 30px solid transparent;
    font-family: $font-nunito;
    background-color: map-get($color, "white");
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 1;
    &::before,
    &::after {
      content: "";
      position: absolute;
      border: 1px solid map-get($color, primary);
      width: 100%;
      height: 100%;
      visibility: visible;
      z-index: -1;
    }
    &::before {
      height: calc(100% - 30px);
    }
    &::after {
      width: calc(100% - 30px);
    }
    .user-name,
    .user-role {
      color: map-get($color, primary);
      text-align: center;
    }
    .user-name {
      @include pxRem(font-size, 35);
      @include pxRem(margin-bottom, 5);
      line-height: 1;
    }
    .user-role {
      @include pxRem(font-size, 20);
      @include pxRem(margin-bottom, 20);
      font-style: italic;
    }
    .user-desc {
      color: map-get($color, "grey");
      @include pxRem(margin-bottom, 40);
    }
    .user-socials {
      @include flex-center-y();
    }
    .user-social-item {
      @include flex-centered();
      @include pxRem(width, 22);
      @include pxRem(height, 22);
      @include pxRem(border-radius, 3);
      @include pxRem(margin, 0 3);
      @include pxRem(font-size, 14);
      color: map-get($color, "white");
      background-color: map-get($color, primary);
      a {
        display: flex;
      }
    }
  }
}
.section-story {
  @include pxRem(margin-bottom, 80);
  position: relative;
  .section-title {
    @include flex-centered();
    @include pxRem(padding, 170 0);
    position: relative;
    color: map-get($color, "white");
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(map-get($color, secondary), 0.8);
      z-index: 1;
    }
    .section-divider-top,
    .section-divider-bottom {
      @include pxRem(height, 130);
      position: absolute;
      left: 0;
      width: 100%;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        z-index: 0;
        width: 0;
        height: 0;
        border-style: solid;
      }
    }
    .section-divider-top {
      @include pxRem(padding-top, 170);
      top: 0;
      &::before {
        top: 0;
        border-width: 130px 105vw 0 0;
        border-color: map-get($color, "white") transparent transparent transparent;
        z-index: 2;
      }
    }
    .section-divider-bottom {
      @include pxRem(padding-bottom, 170);
      bottom: 0;
      &::before {
        bottom: 0;
        border-width: 130px 0 0 105vw;
        border-color: transparent transparent transparent map-get($color, "white");
        border-left-color: map-get($color, "white");
        z-index: 3;
      }
    }
  }
  .stories {
    position: relative;
    z-index: 1;
    &::before {
      @include pxRem(width, 1);
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      background-color: map-get($color, primary);
      translate: 50% 0;
      z-index: -1;
    }
    .story-item {
      @include flex-column-centered();
      @include pxRem(margin-bottom, 100);
      .story-timeline {
        max-width: 100%;
      }
    }
    .story-final {
      @include pxRem(margin-top, 120);
      @include pxRem(padding, 20 10);
      color: map-get($color, primary);
      background-color: map-get($color, "white");
      text-align: center;
      .story-icon {
        @include pxRem(font-size, 80);
        line-height: 1;
      }
      h2 {
        @include pxRem(font-size, 70);
        line-height: 1;
        margin: 0;
      }
    }
  }
  .story-year {
    @include flex-centered();
    @include pxRem(font-size, 32);
    @include pxRem(padding, 8 0);
    @include pxRem(margin-bottom, 70);
    display: inline-flex;
    position: relative;
    color: map-get($color, primary);
    background-color: map-get($color, "white");
    .double-border {
      @include pxRem(padding, 20 50);
      background-color: map-get($color, "white");
    }
  }
  .timeline-item {
    position: relative;
    &::before,
    &::after {
      @include pxRem(width, 400);
      @include pxRem(height, 375);
      content: "";
      position: absolute;
      background-image: url("../../assets/images/flower-medium.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &::before {
      transform: translate(57%, -60%);
      top: 40%;
      left: 57%;
      z-index: -1;
    }
    &::after {
      transform: translate(50%, 20%);
      top: 100%;
      right: 50%;
      rotate: 180deg;
      z-index: -2;
    }
    &.timeline-flex {
      display: flex;
      &::before {
        display: none;
      }
      &::after {
        transform: translate(0, 100%);
        top: 50%;
        left: -50%;
        scale: -1 1;
        rotate: -90deg;
      }
    }
    &.timeline-video {
      &::before {
        top: 30%;
      }
      &::after {
        transform: translate(50%, -100%);
        left: 5%;
        bottom: 30%;
        top: auto;
        right: auto;
      }
      .timeline-date {
        top: 22%;
        left: 50%;
      }
      .timeline-images {
        transform: translateY(150px);
        .image-item {
          &:nth-child(even) {
            margin-top: 10%;
            z-index: 2;
          }
          &:nth-child(odd) {
            transform: translateY(-50%);
            margin-top: 45%;
          }
        }
      }
      .timeline-story {
        transform: translateX(-25%);
        position: relative;
        margin: -30% 0 0 50%;
      }
    }
  }
  .timeline-date {
    @include pxRem(padding, 20 28);
    @include pxRem(font-size, 22);
    transform: translateY(-50%);
    display: inline-flex;
    position: absolute;
    top: 30%;
    left: 50%;
    z-index: 1;
    color: map-get($color, "white");
    border: 10px solid map-get($color, "white");
    background-color: map-get($color, primary);
    .double-border {
      &::before,
      &::after {
        border-color: map-get($color, "white");
      }
    }
  }
  .timeline-images {
    display: flex;
  }
  .image-item {
    @include clearfix();
    width: 50%;
    border: 10px solid map-get($color, "white");
    float: left;
    align-self: flex-start;
    &:nth-child(odd) {
      margin-left: 5%;
    }
    &:nth-child(even) {
      @include pxRem(margin-top, 25%);
      margin-left: -8%;
    }
  }
  .timeline-story {
    @include clearfix();
    @include pxRem(padding, 30 25);
    position: relative;
    max-width: 40%;
    align-self: flex-start;
    color: map-get($color, "white");
    border: 10px solid map-get($color, "white");
    background-color: map-get($color, primary);
    z-index: 4;
    margin: -30% 0 0 14%;
    .double-border {
      @include pxRem(padding, 40 25);
      &::before,
      &::after {
        border-color: map-get($color, "white");
      }
    }
    .title {
      @include flex-center-x();
      @include pxRem(margin-bottom, 15);
      flex-direction: column;
      position: relative;
      text-align: center;
    }
    .underline-icon {
      @include pxRem(margin-bottom, 20);
      color: map-get($color, "white");
    }
  }
  .timeline-slider {
    width: 65%;
    position: relative;
    & + .timeline-story {
      @include pxRem(min-width, 400);
      transform: translateX(100%);
      margin: 30% 0 0 -50%;
    }
    .slick-slider {
      border: 10px solid map-get($color, "white");
      z-index: -1;
      img {
        display: inline-block;
        vertical-align: middle;
      }
    }
    .timeline-slider-actions {
      @include flex-centered();
      transform: translate(0, -50%);
      display: inline-flex;
      position: absolute;
      left: 0;
      background-color: map-get($color, "white");
      border: 10px solid map-get($color, "white");
    }
    .timeline-slider-button {
      @include flex-centered();
      @include pxRem(font-size, 25);
      @include pxRem(width, 38);
      @include pxRem(height, 54);
      color: map-get($color, primary);
      background-color: map-get($color, "white");
      border: 1px solid map-get($color, primary);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      outline: none;
      &:hover {
        color: map-get($color, "white");
        background-color: map-get($color, primary);
      }
      &:first-child {
        margin-right: 8px;
      }
    }
  }
  .player-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 60%;
    border: 10px solid map-get($color, "white");
    video {
      display: block;
    }
  }
}
.section-invitation {
  @include pxRem(padding, 70);
  @include pxRem(margin-bottom, 50);
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(map-get($color, secondary), 0.8);
  }
  .section-divider-top,
  .section-divider-bottom {
    position: absolute;
    left: 0;
    width: 100%;
    height: 130px;
  }
  .section-divider-top {
    top: 0;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 130px 105vw 0 0;
      border-color: #fff transparent transparent transparent;
    }
  }
  .section-divider-bottom {
    bottom: 0;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 3;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 130px 105vw;
      border-color: transparent transparent #fff transparent;
    }
  }
  .section-title {
    color: map-get($color, "white");
  }
  .section-content {
    @include pxRem(margin-bottom, 120);
  }
  .double-border {
    @include pxRem(padding, 20);
    &::before,
    &::after {
      border-color: map-get($color, "white");
    }
  }
  .right-side {
    background-color: rgba(map-get($color, primary), $alpha: 0.6);
  }
  .wedding-invitation {
    color: map-get($color, "white");
    width: 100%;
  }
  .wedding-invitation-cover {
    @include flex-centered();
    flex-direction: column;
    height: 100%;
    h2 {
      @include pxRem(font-size, 100);
      position: relative;
      text-align: center;
      line-height: 1;
      &::before,
      &::after {
        @include pxRem(width, 170);
        @include pxRem(height, 150);
        content: "";
        position: absolute;
        background-image: url("../../assets/images/leaf.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
      &::before {
        transform: translate(-40%, 40%);
        bottom: 100%;
        left: 100%;
      }
      &::after {
        transform: translate(40%, -30%) rotate(180deg);
        top: 100%;
        right: 100%;
      }
      p {
        @include pxRem(font-size, 20);
        @include pxRem(padding, 8 0 0);
        margin: 0;
        line-height: 0;
        text-transform: uppercase;
      }
    }
  }
  .wedding-invitation-content {
    @include pxRem(padding, 30 10);
    @include flex-centered();
    flex-direction: column;
    .tagline,
    p {
      text-align: center;
    }
    .logo {
      @include pxRem(margin-bottom, 30);
      width: 50%;
    }
    .tagline {
      @include pxRem(margin-bottom, 30);
      @include pxRem(font-size, 18);
      text-transform: uppercase;
    }
    .wedding-datetime {
      @include flex-centered();
      @include pxRem(padding, 45 0);
      @include pxRem(margin-bottom, 25);
      flex-direction: column;
      position: relative;
      font-weight: 600;
      &::before,
      &::after {
        @include pxRem(width, 120);
        @include pxRem(height, 19);
        content: "";
        position: absolute;
        background-image: url("../../assets/images/white-arrow.svg");
        background-repeat: no-repeat;
        background-position: center center;
      }
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
        rotate: 180deg;
      }
      .date {
        @include pxRem(font-size, 26);
        @include pxRem(margin-bottom, 10);
      }
      .time {
        @include pxRem(font-size, 22);
      }
    }
    .wedding-place {
      @include pxRem(font-size, 18);
      p {
        @include pxRem(margin-bottom, 5);
      }
      .restaurant-name {
        @include pxRem(font-size, 22);
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}
.section-location {
  .title {
    margin-top: 0;
    color: map-get($color, primary);
  }
  .google-map {
    @include pxRem(height, 550);
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .pin {
    position: relative;
  }
  .pin-icon {
    @include pxRem(font-size, 40);
    color: map-get($color, "red");
  }
  .pin-info {
    @include pxRem(padding, 12);
    @include pxRem(width, 230);
    @include pxRem(font-size, 14);
    @include pxRem(border-radius, 4);
    transform: translateX(-25%);
    position: absolute;
    bottom: calc(100% + 15px);
    background-color: map-get($color, "white");
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .name {
      @include pxRem(margin-bottom, 5);
      font-weight: 600;
    }
    .address {
      span {
        font-weight: 600;
      }
    }
  }
}
