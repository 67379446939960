.double-border {
  @include flex-centered();
  @include pxRem(padding, 20);
  position: relative;
  flex-direction: column;
  &::before,
  &::after {
    content: "";
    position: absolute;
    border: 1px solid map-get($color, primary);
  }
  &::before {
    @include pxRem(top, 8);
    left: 0;
    width: 100%;
    height: calc(100% - 16px);
  }
  &::after {
    @include pxRem(left, 8);
    top: 0;
    width: calc(100% - 16px);
    height: 100%;
  }
}
