// == Breakpoints ===
$breakpoints: (
  smallest: 375px,
  small: 480px,
  medium: 768px,
  wide: 960px,
  large: 1024px,
  large-max: 1025px,
  large-pro: 1367px,
  largest: 1280px,
  super-largest: 1441px,
  pc: 1920px
);

$color: (
  primary: #487eb0,
  secondary: #2f3640,
  "white": #FFFFFF,
  "grey": #73777b,
  "red": #e74c3c
);

$layout: (
  header: 89px,
  office-nav: 60px,
  office-nav-tablet: 45px,
  room-nav: 252px,
  room-nav-tablet: 200px,
  footer: 58px,
  header-mobile: 100px,
  sidebar-right-mobile: 40px,
  sidebar-left-mobile: 60px,
  room-title-mobile: 44px,
  sidebar-collapsed: 20px,
  sidebar-chat: 375px,
  header-chat: 70px,
  footer-chat: 98px,
  room-layout-right: 300px
);

$side-bar-width: map-get($layout, office-nav) + map-get($layout, room-nav);
$room-header-height: map-get($layout, header);

$z-index: (
  header: 8,
  sidebar: 10,
  dialog: 100,
  footer: 12,
  rightMenu: 14,
  widget: 10001,
  personalized-view: 10000,
  toast: 99999,
  canvas: 1000000000,
  block-action: 999999999
);

$font-size: (
  xxs:    10px,
  xs:     12px,
  sm:     14px,
  normal: 16px,
  md:     18px,
  lg:     21px,
  xl:     24px,
);

$seat-size: (
  sm: 38px,
  md: 40px,
  lg: 70px,
  xl: 110px
);

$container-breakpoints: (
  medium: 440,
  large: 840
);

$border-radius: (
  sm: 4px,
  md: 5px
);

$avatar-size: (
  xl: 110px,
  lg: 60px,
  ultra-wide: 46px,
  wide: 38px,
  md: 30px,
  sm: 27px,
  xs: 20px,
  '42': 42px
);

$font-body: "Poppins", sans-serif;
$font-vibes: "Great Vibes", cursive;
$font-nunito: 'Nunito', sans-serif;
