// === RESPOND ABOVE ===
// @include respond-above(small) {}
@mixin respond-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

// === RESPOND BELOW ===
// @include respond-below(small) {}
@mixin respond-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

// === RESPOND BETWEEN ===
// @include respond-between(small, medium);
@mixin respond-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn "Your lower breakpoint was invalid: #{$lower}.";
    }
    @if (map-has-key($breakpoints, $upper) == false) {
      @warn "Your upper breakpoint was invalid: #{$upper}.";
    }
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}
@mixin box-shadow($shadow) {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
}
@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
  -o-transform: $transforms;
	transform: $transforms;
}
// Rotate
@mixin rotate($deg) {
	@include transform(rotate(#{$deg}deg));
}
// Scale
@mixin scale($scale) {
	@include transform(scale($scale));
}
// Translate
@mixin translate($x, $y) {
	@include transform(translate($x, $y));
}
// Translate
@mixin translateX($x) {
	@include transform(translateX($x));
}
// Translate
@mixin translateY($y) {
	@include transform(translateY($y));
}
// Skew
@mixin skew($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}
// Transform Origin
@mixin transform-origin ($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
	transform-origin: $origin;
}
@mixin opacity($opacity) {
  -webkit-opacity: $opacity;
  -moz-opacity: $opacity;
  opacity: $opacity;
}
@mixin clearfix() {
  display: inline-block;
  float: left;
  &::after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
}
@mixin font-icon($icon) {
  content: map-get($font-icon, $icon);
  font-family: map-get($font-family, icon);
  -webkit-font-smoothing: antialiased;
}
@mixin border-box($border-box) {
  -webkit-box-sizing: $border-box;
  -moz-box-sizing: $border-box;
  box-sizing: $border-box;
}
@mixin text-fill-color($prColor) {
  -webkit-text-fill-color: map-get($color, $prColor) !important;
}
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  flex-direction: $direction;
}

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
     -moz-flex-wrap: $wrap;
      -ms-flex-wrap: $wrap;
          flex-wrap: $wrap;
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  animation: $animation;
}

@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin ellipsis-special($number : null) {
  overflow: hidden;
  @if type-of($number) != number {
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @else {
    display: box;
    display: -moz-box;
    display: -webkit-box;
    display: -ms-box;
    box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-line-clamp: $number;
    -moz-line-clamp: $number;
    -webkit-line-clamp: $number;
    word-wrap: break-word;
    white-space: normal;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
  }
  // Prevent Safari from showing tooltip when text overflow is hidden with ellipsis
  &::after {
    content: '';
    display: block;
  }
}

// === CONVERT PXREM ===
@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@function convertRootPxToEm($value) {
  // check for null:
  @if($value == null) {
    @return $value;
  }
  // assume em:
  @if (unitless($value) or unit($value) == em) {
    @return 0em + $value;
  }
  @else {
    @return 0em + strip-units($value) / 16;
  }
}

@function convertPxToRem($px) {
  @return 0rem + strip-units($px) / 16;
}

@mixin pxRem($property, $values) {
  // Create a couple of empty lists as output buffers:
  $px-values: ();
  $rem-values: ();

  // Ensure $values is a list.
  @if type-of($values) != "list" {
    $values: join((), $values);
  }

  // Loop through the $values list:
  @each $value in $values {
    @if type-of($value) == number and (unitless($value) or unit($value) == px) {
    $px-values: join($px-values, 0px + ($value));
      $rem-values: join($rem-values, convertPxToRem($value));
    }
    @else {
    // no conversion:
    $px-values: join($px-values, $value);
    $rem-values: join($rem-values, $value);
    }
  }

  #{$property}: $px-values;

  // don't add media query if it's not needed:
  @if $px-values != $rem-values {
    // @media only all {
      #{$property}: $rem-values;
    // }
  }
}
// == End / Convert Px To Rem

// Passing color variable defined in variables.scss instead of passing color code
@mixin button-variant($text-color, $bg-color, $border-color, $hover-txt-color, $hover-bg, $hover-border: "", $height: 36) {
  @include pxRem(padding, 0 15);
  @include pxRem(height, $height);
  @include pxRem(min-width, 88);
  display: flex;
  align-items: center;
  color: map-get($color, $text-color);
  @if ($bg-color != "") {
    background: map-get($color, $bg-color);
  }
  @if ($border-color != "") {
    border: 1px solid map-get($color, $border-color);
  }
  &:hover:enabled {
    @if ($hover-txt-color != "") {
      color: map-get($color, $hover-txt-color);
    }

    background: map-get($color, $hover-bg);
    @if ($hover-border != "") {
      border-color: map-get($color, $hover-border);
    } @else {
      border-color: map-get($color, $hover-bg);
    }
  }
}

@mixin button-circle($text-color, $bg-color: "", $border-color: "") {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: map-get($color, $text-color);
  @if ($bg-color != "") {
    background: map-get($color, $bg-color);
  }
  @if ($border-color != "") {
    border: 1px solid map-get($color, $border-color);
  }
}

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width:  $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top:  50%;
    left: 50%;
    width:  $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        &.has-user {
          display: flex;
          justify-content: center;
          align-items: center;
          transform:
            rotate($rot * 1deg)
            translate($circle-size / 2 + 0.5)
            rotate($rot * -1deg);
        }
        transform:
          rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate(90deg);
      }

      $rot: $rot + $angle;
    }
  }
}

@mixin filter($filter-type,$filter-amount) {
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}
@mixin scale($scale) {
  transform: scale($scale);
}

@mixin flex-column() {
  display: flex;
  flex-direction: column;
}

@mixin flex-center-y() {
  display: flex;
  align-items: center;
}

@mixin flex-center-x() {
  display: flex;
  justify-content: center;
}

@mixin flex-centered() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-centered() {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin flex-center-between() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-center-end() {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
